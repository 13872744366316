import { MessageAttachment } from '@weavix/models/src/channel/channel-message';

export interface LinkPreviewRequest {
    url: string;
}

export const ALLOWED_IMAGE_FIlE_ATTACHMENT_TYPES: string[] = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.webp',
    '.bpm',
    '.dip',
    '.ico',
    '.jfif',
];
export const ALLOWED_VIDEO_FILE_ATTACHMENT_TYPES: string[] = [
    '.mp4',
    '.webm',
    '.ogg',
    '.mov',
];
export const NOT_ALLOWED_FILE_TYPES: string[] = [
    '.exe',
];

export interface RadioMessageAttachment extends MessageAttachment {
    loading?: boolean;
}

export enum AddCarouselActionType {
    APPEND = 'append',
    PREAPPEND = 'preappend',
}
